import React from "react";
import { useTheme } from '@mui/material/styles';
import { FaGithub, FaLinkedin } from "react-icons/fa";
import './AboutMe.css';

function AboutMe() {
  const theme = useTheme();

  return (
    <div className="AboutMePage" style={{ color: theme.palette.text.primary }}>
      <h1 style={{ color: theme.palette.text.primary }}>About</h1>
      <div className="DescriptionMe">
        <p>
        Hello! I'm Bishwas Bhattarai, a third-year student at the University of Alberta, pursuing a double major in Computer Science and Mathematics. My passion lies in exploring various fields such as deep learning, reinforcement learning, generative models, computer graphics, compiler design, and software development. Investigating the intersections of these domains not only fuels my curiosity but also motivates me to further examine their applications and implications.          <br/> Here is my <a href="URL_to_CV" target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.primary.main }}>CV</a>.
        </p>
      </div>
      <div className="Links">
        <a href="https://github.com/bishwasz" target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.text.primary }}>
          <FaGithub />
        </a>
        <a href="https://www.linkedin.com/in/bishwas-bhattarai-269938219/" target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.text.primary }}>
          <FaLinkedin />
        </a>
        {/* <a href="https://leetcode.com/bishwas2026/" target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.text.primary }}>
          <SiLeetcode />
        </a> */}
      </div>
    </div>
  );
}

export default AboutMe;





// import React, { useEffect } from "react";
// import { useTheme } from '@mui/material/styles';
// import { FaGithub, FaLinkedin } from "react-icons/fa";
// import { motion } from 'framer-motion';
// import './AboutMe.css';

// function AboutMe() {
//   const theme = useTheme();

//   useEffect(() => {
//     const handleDrag = () => {
//       const ballElement = document.querySelector('.draggable-ball');
//       const words = document.querySelectorAll('.DescriptionMe span');

//       if (ballElement) {
//         const ballRect = ballElement.getBoundingClientRect();

//         words.forEach(word => {
//           const wordRect = word.getBoundingClientRect();
//           const isOverlap = !(
//             ballRect.right < wordRect.left ||
//             ballRect.left > wordRect.right ||
//             ballRect.bottom < wordRect.top ||
//             ballRect.top > wordRect.bottom
//           );

//           if (isOverlap) {
//             word.classList.add('inverted');
//           } else {
//             word.classList.remove('inverted');
//           }
//         });
//       }
//     };

//     document.addEventListener('mousemove', handleDrag);
//     return () => document.removeEventListener('mousemove', handleDrag);
//   }, []);

//   const text = "Hello! I'm Bishwas Bhattarai, a third-year student at the University of Alberta, pursuing a double major in Computer Science and Mathematics. My passion lies in exploring various fields such as deep learning, reinforcement learning, generative models, computer graphics, compiler design, and software development. Investigating the intersections of these domains not only fuels my curiosity but also motivates me to further examine their applications and implications. Here is my CV.";

//   return (
//     <div className="AboutMePage" style={{ color: theme.palette.text.primary }}>
//       <h1 style={{ color: theme.palette.text.primary }}>About</h1>
//       <div className="DescriptionMe">
//         {text.split(' ').map((word, index) => (
//           <span key={index}>{word}&nbsp;</span>
//         ))}
//       </div>
//       <div className="Links">
//         <a href="https://github.com/bishwasz" target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.text.primary }}>
//           <FaGithub />
//         </a>
//         <a href="https://www.linkedin.com/in/bishwas-bhattarai-269938219/" target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.text.primary }}>
//           <FaLinkedin />
//         </a>
//       </div>
//       <motion.div
//         className="draggable-ball"
//         drag
//         dragConstraints={{ top: 0, left: -500, right: 500, bottom: 600 }}
//         dragElastic={0.5}  // Adjust elasticity of the drag
//         dragMomentum={true}  // Enable momentum
//         dragTransition={{ bounceStiffness: 600, bounceDamping: 20 ,timeConstant: 1000, power: 0.1}}  // Fine-tune the momentum effect
//       />
//     </div>
//   );
// }

// export default AboutMe;